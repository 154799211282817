// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  clientSettings: {
    authority: 'http://cityusa.iam.test.rokersmartpermits.com:5000',
    client_id: 'd2318814-5869-4d09-ac0f-ee0fc0397c70',
    scope: 'openid profile iam',
    acr_values:'tenant:cityusa'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
