import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { OidcAuthLibService } from 'oidc-auth-lib';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: OidcAuthLibService,   
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const accessUrl: string = state.url;
    sessionStorage.setItem('auth-redirect', accessUrl);

    const accesstoken = this.authService.getAuthorizationHeaderValue();
    const isUserLoggedIn = this.authService.isLoggedInObs();

    isUserLoggedIn.subscribe((loggedin) => {
      // if(loggedin) {
      //   if(environment.realTimeNotification === 'on') {
      //     this.notificationService.connect(environment.serviceUrl + 'webnotification/notification', accesstoken)
      //   }
      // }
      if (!loggedin) {
        this.authService.startAuthentication();
      } 
      else {
        const claimDetails = this.authService.getClaims();
        if (         
          (claimDetails.mut === '2' &&
            !this.authService.isExistInScope('backoffice')) ||
          (claimDetails.mut === '3' &&
            this.authService.isExistInScope('backoffice'))
        ) {
          this.router.navigate(['/access-denied']);
          this.authService.isAuthorizeUsr = false;
          return;
        }        
        
      }
    });

    return isUserLoggedIn;
  }
}
